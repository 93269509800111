<template>
  <v-container>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-clock-outline</v-icon>
        Cronograma de Mídias Sociais
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <p class="text-subtitle-1 pa-4 ">
          Em desenvolvimento ...
        </p>
        <!-- <ListaCronogramaMidiaSociais /> -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "CronogramaMidiasSociais",

  components: {
    // ListaCronogramaMidiaSociais: () =>
    //   import("./components/ListaCronogramaMidiaSociais.vue"),
  },
};
</script>

<style lang="scss"></style>
